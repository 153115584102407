<template>
  <app-page-layout :title="$t('leave.crud.create')" icon="mdi-airplane-takeoff" @close="leavePage">
    <template #content>
      <v-window v-model="step">
        <v-window-item :value="1">
          <validation-observer ref="form">
            <v-layout row wrap>
              <v-flex xs12>
                <v-radio-group v-model="leaveRequestType" mandatory row>
                  <v-radio :label="$t('leave.daily')" value="FULL_DAY" />
                  <v-radio :label="$t('leave.hourly')" value="HOURLY" />
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex :class="leaveRequestType === 'FULL_DAY' ? 'xs6' : 'xs12'">
                <v-menu v-model="beginDateMenu" :close-on-content-click="false" :nudge-bottom="45" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider :name="$t('leave.begin_date')" rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="beginDate"
                        clearable
                        :label="$t('leave.begin_date')"
                        :error-messages="errors"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="beginDate = null"
                      />
                    </validation-provider>
                  </template>
                  <v-date-picker v-model="beginDate" first-day-of-week="1" @change="beginDateMenu = false" />
                </v-menu>
              </v-flex>
              <v-flex xs6 v-if="leaveRequestType === 'FULL_DAY'">
                <v-menu v-model="endDateMenu" :close-on-content-click="false" :nudge-bottom="45" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider :name="$t('leave.end_date')" rules="required" v-slot="{ errors }">
                      <v-text-field
                        :disabled="!beginDate"
                        v-model="endDate"
                        clearable
                        :error-messages="errors"
                        :label="$t('leave.end_date')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="endDate = null"
                      />
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    first-day-of-week="1"
                    :min="beginDate"
                    @change="endDateMenu = false"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="leaveRequestType === 'HOURLY'">
              <v-flex xs3>
                <v-menu
                  ref="menu"
                  v-model="beginTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider :name="$t('leave.begin_time')" rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="beginTime"
                        :label="$t('leave.begin_time')"
                        readonly
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </validation-provider>
                  </template>
                  <v-time-picker
                    v-if="beginTimeMenu"
                    v-model="beginTime"
                    no-title
                    full-width
                    format="24hr"
                    @click:minute="beginTimeMenu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs3>
                <v-menu
                  ref="menu"
                  v-model="endTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider :name="$t('leave.end_time')" rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="endTime"
                        :label="$t('leave.end_time')"
                        readonly
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </validation-provider>
                  </template>
                  <v-time-picker
                    v-if="endTimeMenu"
                    v-model="endTime"
                    full-width
                    no-title
                    format="24hr"
                    @click:minute="endTimeMenu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-switch
                  class="mt-2"
                  v-model="leaveRequest.isNextDay"
                  :true-value="true"
                  :false-value="null"
                  color="primary"
                  :label="$t('leave.isNexyDayMessage')"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <validation-provider :name="$t('leave.type')" rules="required" v-slot="{ errors }">
                  <leave-type
                    :disabled="!beginDate && !endDate"
                    v-model="leaveAccrual"
                    :begin-date="beginDate"
                    :leave-request-type="leaveRequestType"
                    :error-messages="errors"
                    return-object
                    @change="setLeaveTypeDurations"
                  />
                </validation-provider>
              </v-flex>
              <v-flex xs12>
                <validation-provider
                  :name="$t('global.address')"
                  :rules="{ required: isAddressRequired, max: 512 }"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    :label="$t('global.address')"
                    v-model.trim="leaveRequest.address"
                    :counter="512"
                    :error-messages="errors"
                    rows="1"
                  />
                </validation-provider>
              </v-flex>
              <v-flex xs12>
                <validation-provider
                  :name="$t('global.explanation')"
                  :rules="{ required: isExplanationRequired, max: 512 }"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    :label="$t('global.explanation')"
                    v-model.trim="leaveRequest.explanation"
                    :counter="512"
                    :error-messages="errors"
                    rows="1"
                  />
                </validation-provider>
              </v-flex>
            </v-layout>
          </validation-observer>
          <v-layout row wrap>
            <v-flex xs12 v-if="isDocumentRequired && requiredDocuments.length">
              <v-subheader class="px-0">
                {{ $t("leave.requests.requested_leave_type_documents") }}
                <v-divider inset />
              </v-subheader>
              <v-timeline dense clipped class="mb-3">
                <v-timeline-item
                  large
                  v-for="(item, index) in requiredDocuments"
                  :key="index"
                  color="blue"
                  icon="mdi-attachment"
                >
                  <v-card flat color="grey lighten-5">
                    <v-card-text>
                      {{ item.name }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-flex>
            <v-flex xs12>
              <v-layout justify-center align-center>
                <v-btn depressed color="primary" :loading="uploading" :disabled="uploading" @click="onPickFile">
                  <template v-slot:loader>
                    <span>{{ $t("global.loading_message") }}</span>
                    <v-progress-circular class="ml-2" indeterminate :size="22" :width="2" />
                  </template>
                  <span class="white--text">{{ $t("buttons.file_upload") }}</span>
                  <v-icon right color="white">mdi-cloud-upload</v-icon>
                </v-btn>
                <input
                  type="file"
                  style="display: none"
                  ref="fileInput"
                  accept="application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, image/*"
                  @change="onFilePicked"
                />
              </v-layout>
            </v-flex>
            <template v-if="uploadedFiles.length">
              <v-flex xs12>
                <v-subheader class="px-0">
                  {{ $t("global.uploaded_files") }}
                  <v-divider inset />
                </v-subheader>
              </v-flex>
              <v-flex xs12>
                <v-slide-x-transition mode="out-in" class="mb-2 py-0" group tag="div">
                  <template v-for="(item, index) in uploadedFiles">
                    <v-card flat color="grey lighten-4" class="mb-2" :key="index">
                      <v-list-item avatar>
                        <v-list-item-avatar>
                          <v-icon>mdi-attachment</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.fileName }}</v-list-item-title>
                          <v-list-item-subtitle>{{ $helpers.humanFileSize(item.size) }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn flat icon @click="onRemoveFile(item.url, index)">
                            <v-icon :size="20">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </template>
                </v-slide-x-transition>
              </v-flex>
            </template>
          </v-layout>
        </v-window-item>
        <v-window-item :value="2">
          <v-row>
            <v-col cols="12">
              <leave-request-information
                :leave-request-days="leaveRequest.leaveRequestDays"
                :leave-accrual="leaveAccrual"
                :date-range-count="dateRangeCount"
                :leave-request-type="leaveRequestType"
                :extra-day="leaveRequest.extraDay"
                :extra-minute="leaveRequest.extraMinute"
                :calculating="calculating"
                :total-count="totalCount"
                :total-leave-hour="totalLeaveHour"
              />
            </v-col>
            <v-col cols="12" v-for="(day, index) in leaveRequest.leaveRequestDays" :key="index">
              <leave-request-day
                :leaveAccrual="leaveAccrual"
                :leave-type-durations="leaveTypeDurations"
                v-model="leaveRequest.leaveRequestDays[index]"
                :index="index"
                editable
              />
            </v-col>
          </v-row>

          <template v-if="extraDays.length">
            <v-row>
              <v-col cols="12">
                <div class="title mb-3 mt-2">
                  {{ $t("leave.requests.days_off_automatically") }}
                </div>
              </v-col>
              <v-col cols="12" v-for="(day, index) in extraDays" :key="day.date">
                <leave-request-day :leaveAccrual="leaveAccrual" v-model="extraDays[index]" :index="index" editable />
              </v-col>
            </v-row>
          </template>
        </v-window-item>
      </v-window>
    </template>
    <template #footer>
      <app-page-footer>
        <template #left>
          <v-btn v-if="step === 2" text @click="onClickBack">{{ $t("buttons.back") }}</v-btn>
        </template>
        <template #right>
          <v-btn v-if="step === 1" text :loading="loading" @click="onClickContinue">{{ $t("buttons.continue") }}</v-btn>
          <v-btn
            v-if="step === 2"
            text
            :loading="loading"
            :disabled="calculating || leaveRequestDisable"
            @click="onSendLeaveRequest"
            >{{ $t("buttons.save") }}</v-btn
          >
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description index Component Logic
   * @date 4.08.2020
   */

  import LeaveRequestDTO from "../model/LeaveRequestDTO";
  import { FILTER_HOLIDAYS, FILTER_LEAVE_REQUEST, FILTER_WORKPLANS } from "../../query";
  import LeaveRequestDay from "../model/LeaveRequestDay";
  import { LEAVE_REQUEST_DAY_TYPE } from "@/helpers/enums";

  export default {
    name: "create-leave-request",
    data: () => ({
      calculateStop: false,
      notTheSame: false,
      uploading: false,
      selectedUploadFile: null,
      uploadedFiles: [],
      isSaved: false,
      loading: false,
      beginDateMenu: false,
      endDateMenu: false,
      beginDate: null,
      endDate: null,
      beginTimeMenu: false,
      endTimeMenu: false,
      beginTime: null,
      endTime: null,
      step: 1,
      leaveAccrual: null,
      leaveRequest: new LeaveRequestDTO(),
      holidays: [],
      workplans: [],
      employeeWorkplanOffDays: [],
      employeeWorkplanOvertimes: [],
      employeeWorkplanBreakDays: [],
      employeeWorkplans: [],
      leaveRequests: [],
      leaveTypeDurations: [],
      dateRangeCount: 0,
      countOffday: 0,
      countAsWorkday: false,
      requestLeaveAcceptedTotalCount: 0,
      leaveRequestType: "FULL_DAY",
      calculating: false,
      oldLeaveRequestDays: null,
      extraDays: [],
      totalRequestCount: 0,
      totalCount: 0,
      totalLeaveHour: 0
    }),
    components: {
      LeaveType: () => import("../components/leave-type-picker"),
      AppPageFooter: () => import("@/layouts/app-page-footer"),
      LeaveRequestDay: () => import("../components/leave-request-day"),
      LeaveRequestItem: () => import("../components/leave-request-item"),
      LeaveRequestInformation: () => import("../components/leave-request-information")
    },
    watch: {
      leaveRequestType: {
        handler() {
          this.beginDate = null;
          this.endDate = null;
          this.beginTime = null;
          this.endTime = null;
          this.leaveRequest.leaveType = null;
          this.$refs.form.reset();
        },
        deep: true
      },
      leaveAccrual: {
        handler(leaveAccrual) {
          this.countAsWorkday = this.leaveAccrual.leavePolicy.leaveType.restriction.countAsWorkday;
          const includeWeekendsAfter = this.leaveAccrual.leavePolicy.leaveType.restriction.includeWeekendsAfter || 0;
          const result = includeWeekendsAfter ? this.employeeWorkplans.length / includeWeekendsAfter : 0;
          this.leaveRequest.leaveType = leaveAccrual.leavePolicy.leaveType.id;
          this.countOffday = isNaN(result) ? 0 : parseInt(result);
        },
        deep: true
      },
      beginDate: {
        handler(date) {
          if (date) {
            if (this.leaveRequestType === "HOURLY") {
              this.endDate = this.$moment(date).format("YYYY-MM-DD");
            }
            if (this.endDate) {
              this.endDate = this.$moment(date).isAfter(this.endDate) ? date : this.endDate;
              this.fetchHolidays();
              this.fetchEmployeeWorkplans();
              this.setDateRangeChange();
              this.filterLeaveRequestDays();
            }
          }
        },
        deep: true
      },
      endDate: {
        handler(date) {
          if (date) {
            if (this.beginDate) {
              this.fetchHolidays();
              this.fetchEmployeeWorkplans();
              this.setDateRangeChange();
              this.filterLeaveRequestDays();
            }
          }
        },
        deep: true
      },
      "leaveRequest.leaveRequestDays": {
        handler(leaveRequestDays) {
          if (this.oldLeaveRequestDays && JSON.stringify(leaveRequestDays) !== this.oldLeaveRequestDays) {
            if (this.leaveRequestType === "HOURLY") {
              this.calculateHourly(leaveRequestDays);
            } else if (!this.calculateStop) {
              this.calculate(leaveRequestDays);
            }
          }
          if (leaveRequestDays.length) {
            this.oldLeaveRequestDays = JSON.stringify(leaveRequestDays);
          }
          // this.requestLeaveAcceptedTotalCount = leaveRequestDays.reduce((total, item) => {
          //   return item.leaveRequestDayType === "ON_LEAVE" ? total + item.durationValue() : total;
          // }, 0);
          this.calculateStop = false;
        },
        deep: true
      }
    },
    computed: {
      leaveRequestDisable() {
        return !(this.totalCount > 0) && !(this.totalLeaveHour > 0);
      },
      isAddressRequired() {
        return this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.addressRequired;
      },
      isExplanationRequired() {
        return this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.explanationRequired;
      },
      noticePeriod() {
        return (this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.noticePeriod) || 0;
      },
      totalMaxExceedLeaveLeaveBalance() {
        return (this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.maxExceedLeaveBalance) || 0;
      },
      totalBalance() {
        let total =
          this.leaveAccrual.previousBalance +
          this.leaveAccrual.leavePolicy.accrualCalculatedCount -
          this.leaveAccrual.used;
        const fixedTotal = total.toFixed(2);
        return Number(fixedTotal);
      },
      includeWeekendsAfter() {
        return this.leaveAccrual ? this.leaveAccrual.leavePolicy.leaveType.restriction.includeWeekendsAfter || 0 : 0;
      },
      isDocumentRequired() {
        return this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.restriction.documentRequired");
      },
      requiredDocuments() {
        return this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.restriction.documents") || [];
      }
    },
    methods: {
      onPickFile() {
        this.$refs.fileInput.click();
      },
      onFilePicked(e) {
        this.selectedUploadFile = e.target.files[0];
        if (this.selectedUploadFile !== undefined) {
          this.validateFile(this.selectedUploadFile)
            .then(valid => {
              if (valid) this.uploadLeaveRequestFile(this.selectedUploadFile);
            })
            .catch(e => {
              let message = {
                text: "Geçersiz dosya formatı",
                type: "error",
                color: "error"
              };
              this.$eventBus.$emit("snack-bar-notification", message);
            })
            .finally(() => {});
        } else {
          this.selectedUploadFile = null;
          this.$refs.fileInput.value = null;
        }
      },
      validateFile(file) {
        return new Promise((resolve, reject) => {
          const validFormats = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "application/pdf",
            "image/png",
            "image/jpg",
            "image/jpeg"
          ];
          const isFormalExtension = validFormats.includes(file.type);
          const fileSize = file.size / 1024 / 1024 < 8;
          if (!isFormalExtension) reject({ message: "file_format_error" });
          if (!fileSize) reject({ message: "less_than_2_mb_error" });
          if (isFormalExtension && fileSize) resolve(isFormalExtension && fileSize);
          resolve(false);
        });
      },
      onRemoveFile(url, index) {
        if (url) {
          const storageType = "LEAVE_DOCUMENT";
          this.$api.storageService
            .delete(storageType, url)
            .then(({ data }) => {
              this.leaveRequest.documentURLs.splice(index, 1);
              this.uploadedFiles.splice(index, 1);
            })
            .catch(e => {
              let message = {
                text: "Dosya silinemedi",
                type: "error",
                color: "error"
              };
              this.$eventBus.$emit("snack-bar-notification", message);
            })
            .then(() => {});
        }
      },
      leavePage() {
        if (!this.isSaved && this.uploadedFiles.length) this.removeAllUploadedFiles();
        this.uploadedFiles = [];
        this.$router.replace({ name: "leave_requests" });
      },
      timeConvertToMinutes(time) {
        let hour = this.$moment(time, "HH:mm");
        return hour.hour() * 60 + hour.minute();
      },
      async fetchEmployeeWorkplans() {
        this.loading = true;
        await this.$apollo
          .query({
            query: FILTER_WORKPLANS,
            variables: {
              criteria: {
                employees: [this.$store.state.auth.user.id],
                beginDate: this.beginDate,
                endDate: this.endDate
              }
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              if (data.filterWorkplans) {
                this.workplans = [];
                this.employeeWorkplanOffDays = [];
                this.employeeWorkplanOvertimes = [];
                this.employeeWorkplanBreakDays = [];
                this.employeeWorkplans = [];

                this.workplans = data.filterWorkplans.map(row => row.date);
                data.filterWorkplans.forEach(workplan => {
                  const workDay = workplan.assignedShifts.some(
                    shift => shift.shiftType === "FIX" || shift.shiftType === "FLEXIBLE"
                  );
                  // Tüm Mesai günlerini al
                  if (workDay) this.employeeWorkplans.push(workplan.date);
                  // Sadece Dinlenme günü olanları al
                  if (workplan.breakDay) this.employeeWorkplanBreakDays.push(workplan.date);
                  // Fazla mesai vardiyası olanları al
                  if (workplan.assignedShifts) {
                    workplan.assignedShifts.forEach(shift => {
                      if (shift.shiftType === "OVERTIME") {
                        if (!this.employeeWorkplanOvertimes.includes(workplan.date)) {
                          this.employeeWorkplanOvertimes.push(workplan.date);
                        }
                      }
                    });
                  }
                  // Hafta Tatili olanları al
                  if (
                    (workplan.assignedShifts.length === 0 || workplan.assignedShifts[0].shiftType === "OVERTIME") &&
                    !workplan.breakDay
                  ) {
                    this.employeeWorkplanOffDays.push(workplan.date);
                  }
                });
                if (this.leaveAccrual) {
                  this.countAsWorkday = this.leaveAccrual.leavePolicy.leaveType.restriction.countAsWorkday;
                  const includeWeekendsAfter =
                    this.leaveAccrual.leavePolicy.leaveType.restriction.includeWeekendsAfter || 0;
                  const result = includeWeekendsAfter ? this.employeeWorkplans.length / includeWeekendsAfter : 0;
                  this.countOffday = isNaN(result) ? 0 : parseInt(result);
                }
              }
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      },
      async fetchHolidays() {
        await this.$apollo
          .query({
            query: FILTER_HOLIDAYS,
            variables: {
              criteria: {
                beginDate: this.beginDate,
                endDate: this.endDate
              }
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.holidays = data.filterHolidays;
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      },
      async filterLeaveRequestDays() {
        this.loading = true;
        this.leaveRequestDays = [];
        await this.$apollo
          .query({
            query: FILTER_LEAVE_REQUEST,
            variables: {
              criteria: {
                beginDate: this.beginDate,
                endDate: this.endDate,
                employees: [this.$store.state.auth.user.id],
                leaveRequestStatuses: ["APPROVED", "PENDING"]
              }
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              data.filterLeaveRequests.forEach(item => {
                item.leaveRequestDays.forEach(row => this.leaveRequests.push(row));
              });
            }
          })
          .catch(e => {
            console.log("e", e);
          })
          .then(() => {
            this.loading = false;
          });
      },
      offDaysAndHolidaysCriteria(date) {
        const dayCount = this.leaveAccrual.leavePolicy.leaveType.restriction.countAsWorkday
          ? this.employeeWorkplans.length
          : this.dateRangeCount;
        let leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.ON_LEAVE;
        let includeWeekendsAfter = this.leaveAccrual.leavePolicy.leaveType.restriction.includeWeekendsAfter;
        let includeHolidaysAfter = this.leaveAccrual.leavePolicy.leaveType.restriction.includeHolidaysAfter;
        let includeBreakDaysAfter = this.leaveAccrual.leavePolicy.leaveType.restriction.includeBreakDaysAfter;
        let includeAllWeekHolidays =
          !this.leaveAccrual.leavePolicy.leaveType.restriction.countAsWorkday &&
          this.leaveAccrual.leavePolicy.leaveType.restriction.includeAllWeekHolidays;
        let hasHoliday = this.holidays.find(item => item.date === date);

        let hasWorkplanInDate = this.workplans.includes(date);
        if (
          !this.leaveAccrual.leavePolicy.leaveType.restriction.countAsWorkday &&
          !this.leaveAccrual.leavePolicy.leaveType.restriction.includeAllWeekHolidays
        ) {
          if (hasHoliday) {
            leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.HOLIDAY;
            if (hasHoliday.halfDay) {
              leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.ON_LEAVE;
            }
          } else if (this.$helpers.isWeekend(date)) {
            leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.OFFDAY;
          }
        } else if (hasWorkplanInDate) {
          let hasOffDay = this.employeeWorkplanOffDays.includes(date);
          let hasOvertimeDay = this.employeeWorkplanOvertimes.includes(date);
          let hasBreakDays = this.employeeWorkplanBreakDays.includes(date);
          if (hasHoliday && !includeAllWeekHolidays) {
            leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.HOLIDAY;
            // kacıncı günden sonra hafta tatilleri izinden sayılacak
            // if ((includeHolidaysAfter !== null && includeHolidaysAfter > 0 && dayCount >= includeHolidaysAfter) || hasHoliday.halfDay) {
            if (hasHoliday.halfDay) {
              leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.ON_LEAVE;
            }
          } else if (hasBreakDays && !includeAllWeekHolidays) {
            // Kaçıncı günden sonra dinlenme günleri izinden sayılacak
            leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.BREAK;
            if (includeBreakDaysAfter !== null && includeBreakDaysAfter > 0 && dayCount >= includeBreakDaysAfter) {
              leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.ON_LEAVE;
            }
          } else if (hasOffDay && !includeAllWeekHolidays) {
            leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.OFFDAY;
          } else if (hasOvertimeDay && !includeAllWeekHolidays) {
            leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.OFFDAY;
            // kacıncı günden sonra fazla mesai günleri izinden sayılacak
            if (includeWeekendsAfter !== null && includeWeekendsAfter > 0 && dayCount >= includeWeekendsAfter) {
              leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.ON_LEAVE;
            }
          }
        } else {
          if (hasHoliday && !includeAllWeekHolidays) {
            leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.HOLIDAY;
            // kacıncı günden sonra resmi tatiller izinden sayılacak
            if (
              (includeHolidaysAfter !== null && includeHolidaysAfter > 0 && dayCount >= includeHolidaysAfter) ||
              hasHoliday.halfDay
            ) {
              leaveRequestDayType = LEAVE_REQUEST_DAY_TYPE.ON_LEAVE;
            }
          }
        }
        return leaveRequestDayType;
      },
      setDateRangeChange() {
        if (!this.beginDate || !this.endDate) return false;
        let newStartDate = this.$moment(this.beginDate);
        let newEndDate = this.$moment(this.endDate);
        let newDateDiffCount = this.$moment.duration(newEndDate.diff(newStartDate));
        this.dateRangeCount = newDateDiffCount.asDays() + 1;
      },
      setLeaveTypeDurations() {
        this.leaveTypeDurations = [];
        let durations = this.leaveAccrual.leavePolicy.leaveType.restriction.durations.sort();
        let leaveTypeDurations = durations.filter(item => item !== "HOURLY");
        this.leaveTypeDurations = leaveTypeDurations.map(item => {
          return {
            text: this.$t(`leave_duration.${item}`, []),
            value: item
          };
        });
      },
      noticePeriodCriteria() {
        return new Promise((resolve, reject) => {
          if (this.noticePeriod) {
            let nowDate = this.$moment().format("YYYY-MM-DD");
            if (this.beginDate >= nowDate) {
              let noticePeriodBeginDate = this.$moment(this.beginDate)
                .subtract(this.noticePeriod, "days")
                .format("YYYY-MM-DD");
              if (noticePeriodBeginDate < nowDate)
                reject(this.$t("leave.error_messages.notice_period_error", [this.noticePeriod]));
            }
          }
          resolve(true);
        });
      },
      maxConsecutiveCriteria() {
        let minConsecutive = this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.minConsecutive;
        let maxConsecutive = this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.maxConsecutive;
        return new Promise((resolve, reject) => {
          if (maxConsecutive && this.dateRangeCount > maxConsecutive) {
            reject(this.$t("leave.error_messages.max_consecutive_error", [maxConsecutive]));
          } else if (minConsecutive && this.dateRangeCount < minConsecutive) {
            reject(this.$t("leave.error_messages.min_consecutive_error", [minConsecutive]));
          } else {
            resolve(true);
          }
        });
      },
      leaveRequestExists() {
        const now = this.$moment(this.beginDate);
        const end = this.$moment(this.endDate);
        const days = this.$moment.duration(end.diff(now)).asDays();
        let leaveRequestDates = [];
        for (let i = 0; i <= days; i++) {
          leaveRequestDates.push(
            this.$moment(this.beginDate)
              .add(i, "days")
              .format("YYYY-MM-DD")
          );
        }
        let exists = this.leaveRequests.some(leave => leaveRequestDates.includes(leave.date));
        return new Promise((resolve, reject) => {
          exists ? reject("leave_request_exist_error") : resolve(true);
          // exists ? reject(this.$t("leave.error_messages.leave_request_exist_error")) : resolve(true);
        });
      },
      firstLeaveTypeDuration(date) {
        if (!Array.isArray(this.leaveTypeDurations)) return null;
        let isHoliday = this.holidays.find(item => item.date === date);
        return isHoliday && isHoliday.halfDay ? "HALF_DAY" : this.leaveTypeDurations[0].value;
      },
      validateAll() {
        return Promise.all([
          this.leaveRequestExists(),
          this.noticePeriodCriteria(),
          this.maxConsecutiveCriteria(),
          this.totalLeaveBalance()
        ]);
      },
      calculateExtraDays(data) {
        data.totalExtraDays.forEach((value, i) => {
          this.notTheSame = false;
          this.leaveRequest.leaveRequestDays.forEach((val, index) => {
            if (val.date === data.totalExtraDays[i]) {
              this.leaveRequest.leaveRequestDays[index].leaveRequestDayType = "ON_LEAVE";
              this.leaveRequest.leaveRequestDays[index].extraDay = false;
              this.leaveRequest.leaveRequestDays[index].toOnLeave = true;
              this.notTheSame = true;
              this.calculateStop = true;
            }
          });
          if (!this.notTheSame) {
            this.leaveRequest.leaveRequestDays[this.leaveRequest.leaveRequestDays.length] = {
              date: data.totalExtraDays[i],
              duration: "FULL_DAY",
              period: "FIRST",
              leaveRequestDayType: "ON_LEAVE",
              fromTime: null,
              lengthInMinutes: null,
              includeSaturday: false,
              holiday: false,
              weight: 1,
              extraDay: true,
              toOnLeave: false
            };
          }
          this.calculateStop = true;
        });
        // this.extraDays = [];
        // data.extraWeekHolidayDays.forEach(val => {
        //   this.extraDays.push({ date: val });
        // });
        // data.totalConsecutiveWeekHolidayTotalDays.forEach(val => {
        //   this.extraDays.push({ date: val });
        // });
        // this.extraDays.forEach((val, index) => {
        //   this.extraDays[index].duration = "FULL_DAY";
        //   this.extraDays[index].period = "FIRST";
        //   this.extraDays[index].leaveRequestDayType = "ON_LEAVE";
        //   this.extraDays[index].fromTime = null;
        //   this.extraDays[index].lengthInMinutes = null;
        //   this.extraDays[index].includeSaturday = false;
        //   this.extraDays[index].holiday = false;
        //   this.extraDays[index].weight = 1;
        //   this.extraDays[index].isExtra = true;
        // });

        // this.extraDays.forEach((value, indexNo) => {
        //   this.leaveRequest.leaveRequestDays.forEach((val, index) => {
        //     if (value.date === val.date) {
        //       this.leaveRequest.leaveRequestDays.splice(index, 1);
        //     }
        //   });
        // });
      },

      calculate(leaveRequestDays) {
        this.calculating = true;
        const payload = {
          employee: this.$store.state.auth.user.id,
          leaveType: this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.id"),
          leaveRequestDays
        };
        this.$api.leaveRequestService
          .calculate(payload)
          .then(response => {
            if (response.status === 201) {
              this.calculateExtraDays(response.data.data);
              this.leaveRequest.extraDay = response.data.data.totalExtraCount;
              this.leaveRequest.totalLeaveCount = response.data.data.totalRequestCount;
              this.totalRequestCount = response.data.data.totalRequestCount;
              this.totalCount = response.data.data.totalCount;
            } else this.$helpers.showNotification("leave_calculate_error");
          })
          .catch(error => console.log(error))
          .finally(() => (this.calculating = false));
      },
      calculateHourly(leaveRequestDays) {
        this.calculating = true;
        const payload = {
          employee: this.$store.state.auth.user.id,
          leaveType: this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.id"),
          leaveRequestDays
        };
        this.$api.leaveRequestService
          .calculateHourly(payload)
          .then(response => {
            if (response.status === 201) {
              this.totalLeaveHour = response.data.data.totalLeaveHour;
              this.leaveRequest.shiftDuration = response.data.data.shiftDuration;
              // this.leaveRequest.extraMinute = response.data.data.totalExtraMinute;
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.calculating = false));
      },
      onClickContinue() {
        this.$refs.form.validate().then(valid => {
          if (valid || this.leaveRequest.isNextDay) {
            this.validateAll()
              .then(valids => {
                if (!valids.some(item => item === false) || this.leaveRequest.isNextDay) {
                  if (this.isDocumentRequired && this.uploadedFiles.length < this.requiredDocuments.length) {
                    this.$helpers.showNotification(this.$t("required_document_message"));
                    return false;
                  }
                  if (this.leaveRequestType === "HOURLY") {
                    let fromTimeMinutes = this.timeConvertToMinutes(this.beginTime);
                    let toTimeMinutes = this.timeConvertToMinutes(this.endTime);
                    let lengthInMinutes = toTimeMinutes - fromTimeMinutes;
                    if (lengthInMinutes < 0) lengthInMinutes = 1440 + lengthInMinutes;
                    let leaveRequestDayType = this.offDaysAndHolidaysCriteria(this.beginDate);
                    let newRequestDay = new LeaveRequestDay();
                    newRequestDay.date = this.beginDate;
                    newRequestDay.duration = "HOURLY";
                    newRequestDay.leaveRequestDayType = leaveRequestDayType;
                    newRequestDay.fromTime = this.beginTime;
                    newRequestDay.lengthInMinutes = lengthInMinutes;
                    newRequestDay.isExtra = false;
                    this.leaveRequest.leaveRequestDays.push(newRequestDay);
                  } else {
                    for (let i = 0; i < this.dateRangeCount; i++) {
                      let currentDay = this.$moment(this.beginDate)
                        .add(i, "days")
                        .format("YYYY-MM-DD");
                      let leaveRequestDayType = this.offDaysAndHolidaysCriteria(currentDay);
                      let newRequestDay = new LeaveRequestDay();
                      newRequestDay.date = this.$moment(this.beginDate)
                        .add(i, "days")
                        .format("YYYY-MM-DD");
                      newRequestDay.duration = this.firstLeaveTypeDuration(currentDay);
                      newRequestDay.leaveRequestDayType = leaveRequestDayType;
                      if (this.holidays.length > 0)
                        newRequestDay.holiday = this.holidays.map(item => {
                          item.date.includes(currentDay);
                        });
                      newRequestDay.weight = this.leaveAccrual.leavePolicy.leaveType.weight || 1;
                      newRequestDay.isExtra = false;
                      this.leaveRequest.leaveRequestDays.push(newRequestDay);
                    }
                  }
                  this.step = 2;
                }
              })
              .catch(e => {
                this.$helpers.showNotification("leave_request_exist_error");
                console.log(e.toString());
              });
          }
        });
      },
      onClickNextStep() {
        this.step = 3;
      },
      totalLeaveBalance() {
        let maxExceedLeaveBalance = this.leaveAccrual.leavePolicy.leaveType.restriction.maxExceedLeaveBalance;
        const totalBalanceDiffLeaveRequestBalance = Number((this.totalBalance - this.dateRangeCount).toFixed(2));

        return new Promise((resolve, reject) => {
          if (totalBalanceDiffLeaveRequestBalance && totalBalanceDiffLeaveRequestBalance <= 0) {
            if (maxExceedLeaveBalance === null) {
              reject(this.$t("leave.error_messages.total_leave_balance_error1"));
            } else {
              // Eğer izin bakiyesi aşabilirse ve seçilen tarihlerde izinden sayılacak günlerin total balance izin bakiyesi aşabilir değeri ile toplandığı zaman 0 a eşit ya da büyükse izni talep etme hakkı doğar
              if (totalBalanceDiffLeaveRequestBalance + maxExceedLeaveBalance >= 0) {
                resolve(true);
              } else {
                reject(this.$t("leave.error_messages.total_leave_balance_error2", [maxExceedLeaveBalance]));
              }
            }
          } else {
            resolve(true);
          }
        });
      },
      onClickBack() {
        this.extraDays = [];
        this.totalCount = 0;
        this.totalLeaveHour = 0;
        this.totalRequestCount = 0;
        this.step = 1;
        this.leaveRequest.leaveRequestDays = [];
        this.oldLeaveRequestDays = null;
        this.leaveRequest.extraDay = 0;
        this.leaveRequest.shiftDuration = 0;
        this.leaveRequest.leaveType = this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.id");
        this.calculateStop = false;
      },
      onSendLeaveRequest() {
        this.loading = true;
        this.$api.leaveRequestService
          .save(this.leaveRequest)
          .then(({ data }) => {
            if (!data.error) {
              this.$emit("success:save");
              this.isSaved = true;
              this.leavePage();
            }
          })
          .catch(e => {
            console.log("e", e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      uploadLeaveRequestFile(file) {
        const storageType = "LEAVE_DOCUMENT";
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("storageType", storageType);
        this.uploading = true;
        this.$api.storageService
          .upload(formData, {
            onUploadProgress: progressEvent => {}
          })
          .then(({ data }) => {
            if (data.data) {
              this.leaveRequest.documentURLs.push(data.data);
              this.uploadedFiles.push({
                fileName: this.selectedUploadFile.name,
                size: this.selectedUploadFile.size,
                type: this.selectedUploadFile.type,
                url: data.data
              });
            }
          })
          .catch(e => {
            let message = {
              text: "Dosya yüklenemedi",
              color: "error",
              type: "error"
            };
            this.$eventBus.$emit("snack-bar-notification", message);
          })
          .finally(() => {
            this.$refs.fileInput.value = "";
            this.selectedUploadFile = null;
          });
        this.uploading = false;
      },
      removeAllUploadedFiles() {
        const storageType = "LEAVE_DOCUMENT";
        this.$api.storageService
          .multipleDelete(storageType, this.leaveRequest.documentURLs)
          .then(({ data }) => {})
          .catch(e => {
            let message = {
              text: "Dosyalar silinemedi",
              type: "error",
              color: "error"
            };
            this.$eventBus.$emit("snack-bar-notification", message);
          })
          .then(() => {});
      }
    }
  };
</script>

<style scoped></style>
