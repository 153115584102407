import store from "@/store";

export default class LeaveRequestDTO {
  constructor() {
    this.employee = store.state.auth.user.id;
    this.leaveType = null;
    this.leaveRequestDays = [];
    this.address = null;
    this.explanation = null;
    this.documentURLs = [];
    this.bypassApproval = false;
    this.extraDay = 0;
    this.extraMinute = 0;
    this.totalLeaveCount = 0;
    this.isNextDay = false;
    this.shiftDuration = 0;
    this.excludeShiftEvents = false;
    this.shiftDuration = 0;
  }
}
