import gql from "graphql-tag";

export const LEAVE_ACCRUAL = gql`
  query leaveAccruals($employee: ID!, $onlyRequestable: Boolean) {
    leaveAccruals(employee: $employee, onlyRequestable: $onlyRequestable) {
      used
      previousBalance
      leavePolicy {
        accrualCount
        accrualCalculatedCount
        leaveType {
          id
          name
          icon
          color
        }
      }
    }
  }
`;

export const LEAVE_REQUESTS = gql`
  query filterWithPaginateLeaveRequests($criteria: LeaveRequestFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateLeaveRequests(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
        pageNumber
      }
      content {
        id
        leaveType {
          name
          color
          icon
        }
        signatureStatus
        leaveRequestStatus
        status
        requestDateTime
        leaveRequestFormURL
        beginLeaveRequestDay {
          date
          duration
        }
        endLeaveRequestDay {
          date
          duration
        }
        approvedDateTime
        requestDateTime
      }
    }
  }
`;

export const LEAVE_APPROVAL = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingApprovalLeaveRequests {
        id
        leaveRequest {
          id
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          requester {
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          leaveRequestDays {
            date
            duration
            fromTime
            lengthInMinutes
          }
          leaveType {
            id
            name
          }

          requestDateTime
          beginLeaveRequestDay {
            date
          }

          endLeaveRequestDay {
            date
          }
        }
      }
    }
  }
`;

export const EMPLOYEE_LEAVE_ACCRUALS = gql`
  query leaveAccruals($employee: ID!, $beginDate: LocalDate!, $daily: Boolean) {
    leaveAccruals(employee: $employee, beginDate: $beginDate, daily: $daily) {
      previousBalance
      used
      leavePolicy {
        accrualCount
        accrualCalculatedCount
        leaveType {
          id
          name
          color
          icon
          weight
          restriction {
            noticePeriod
            includeWeekendsAfter
            includeHolidaysAfter
            includeBreakDaysAfter
            minConsecutive
            maxConsecutive
            durations
            maxExceedLeaveBalance
            includeSaturday
            countAsWorkday
            consecutiveWeekendLeavePassive
            includeAllWeekHolidays
            documentRequired
            documents {
              name
              url
            }
            addressRequired
            explanationRequired
            requestable
          }
        }
      }
    }
  }
`;

export const FILTER_WORKPLANS = gql`
  query FilterWorkplans($criteria: WorkplanFilterCriteria!) {
    filterWorkplans(criteria: $criteria) {
      date
      breakDay
      assignedShifts {
        id
        shiftType
      }
    }
  }
`;

export const FILTER_HOLIDAYS = gql`
  query filterHolidays($criteria: HolidayFilterCriteria!) {
    filterHolidays(criteria: $criteria) {
      id
      name
      color
      code
      halfDay
      countAsOvertime
      date
    }
  }
`;

export const FILTER_LEAVE_REQUEST = gql`
  query filterLeaveRequests($criteria: LeaveRequestFilterCriteria!) {
    filterLeaveRequests(criteria: $criteria) {
      leaveRequestDays {
        id
        date
      }
    }
  }
`;

export const EMPLOYEE_MANAGERS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      leaveSettings {
        leaveApprover
      }
    }
  }
`;

export const EMPLOYEE_LEAVE_ACCRUAL = gql`
  query leaveAccruals($employee: ID!) {
    leaveAccruals(employee: $employee) {
      used
      previousBalance
      initialLeaveBalance
      initialLeaveBalanceDate
      leavePolicy {
        accrualCalculatedCount
        leaveType {
          id
          name
          icon
          color
        }
      }
    }
  }
`;
